import { IconButton, Stack } from '@mui/material';
import { IResponseOption, QdfQuestion } from '../../../types';
import styles from './GridHeader.module.css';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface GridHeaderProps {
  index: number;
  scope: string;
  onChangeText: Function;
  onRemove: Function;
  cell: QdfQuestion | IResponseOption;
}

const GridHeader = ({ index, scope, onChangeText, onRemove, cell }: GridHeaderProps) => {
  return (
    <th className={styles['header-cell']} align="center" scope={scope}>
      <Stack
        className={styles['header-wrapper']}
        direction="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap">
        <input
          className={styles['header-input']}
          name={cell.text}
          placeholder={`Enter ${scope}`}
          onChange={(e) => onChangeText(index, e)}
          value={cell.text}
          style={{ resize: 'none' }}
        />

        <span className={styles['remove-btn-wrapper']}>
          <IconButton color="error" onClick={() => onRemove(index)}>
            <RemoveCircleIcon data-testid={`remove-${scope}`} />
          </IconButton>
        </span>
      </Stack>
    </th>
  );
};

export default GridHeader;
