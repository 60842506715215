import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { QdfQuestion } from '../../types';
import { QuestionType } from '../../types/panoptic';

import BooleanControl from './BooleanControl';
import styles from './Sidepanel.module.css';

interface SidepanelProps {
  widgetDefinitions: QuestionType;
  qdf: QdfQuestion;
  onVisualChange: Function;
}

const Sidepanel = ({ widgetDefinitions, qdf, onVisualChange }: SidepanelProps) => {
  const supportedOptionTypes = ['bool'];

  const defaultOptions = Object.keys(widgetDefinitions.options).reduce((acc, optionName) => {
    if (supportedOptionTypes.includes(widgetDefinitions.options[optionName].type))
      return { ...acc, [optionName]: widgetDefinitions.options[optionName].default };
    return acc;
  }, {});

  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    onVisualChange({
      ...qdf,
      options: options,
    });
  }, [options, onVisualChange]);

  return (
    <Stack className={styles.panel} spacing={0.5} data-testid="side-panel">
      <p className={styles.title}>Settings</p>

      {Object.keys(widgetDefinitions.options).map(
        (optionName) =>
          widgetDefinitions.options[optionName].type === 'bool' && (
            <BooleanControl
              key={optionName}
              optionName={optionName}
              qdf={qdf}
              widgetDefinitions={widgetDefinitions}
              onOptionChange={setOptions}
            />
          )
      )}
    </Stack>
  );
};

export default Sidepanel;
