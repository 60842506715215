import React, { useRef, useState } from 'react';
import { IResponseOption } from '../../types';
import ResponseOption from '../ResponseOption/ResponseOption';
import { Button } from '@mui/material';

interface ResponseOptionsProps {
  responseOptions?: IResponseOption[];
  onChange?: (inputFields: IResponseOption[]) => void;
  multiple?: boolean;
  showTickbox: boolean;
  name: string;
}

function ResponseOptions({
  responseOptions = [],
  onChange = () => {},
  showTickbox,
  multiple,
  name,
}: ResponseOptionsProps) {
  const [controlValue, setControlValue] = useState('');

  const inputEl = useRef<null | HTMLInputElement>(null);

  const formChangeHandler = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    let data = [...responseOptions];
    data[index] = { ...data[index], text: e.target.value };
    onChange(data);
  };

  const addFields = () => {
    onChange([
      ...responseOptions,
      { text: '', code: responseOptions.length, element: 'qdf:response' },
    ]);
    setTimeout(() => inputEl.current?.focus());
  };

  const removeFields = (index: number) => {
    let data = [...responseOptions];
    data.splice(index, 1);
    onChange(data);
  };

  return (
    <div>
      {responseOptions.map((input: IResponseOption, index: number) => {
        return (
          <ResponseOption
            key={index}
            input={input}
            index={index}
            onChange={formChangeHandler}
            onRemove={removeFields}
            onValueUpdate={setControlValue}
            isSelected={multiple === false && controlValue === `${index}`}
            value={`${index}`}
            multiple={multiple}
            showTickbox={showTickbox}
            name={name}
            inputRef={inputEl}
          />
        );
      })}
      <Button variant="text" onClick={addFields}>
        Add {responseOptions.length > 0 && 'another'} option
      </Button>
    </div>
  );
}

export default ResponseOptions;
