import { ChangeEventHandler } from 'react';
import styles from './EditableQuestionText.module.css';

interface IQuestionText {
  onChangeText: ChangeEventHandler;
  value: string;
}

const EditableQuestionText = ({ onChangeText, value }: IQuestionText) => {
  return (
    <input
      className={styles.question}
      onChange={onChangeText}
      value={value}
      placeholder="Enter your question text..."
    />
  );
};

export default EditableQuestionText;
