import { useState } from 'react';
import { IResponseOption, QdfQuestion } from '../../../types';
import styles from './GridOpen.module.css';

interface GridOpenProps {
  row: QdfQuestion;
  column: IResponseOption;
}

const GridOpen = ({ row, column }: GridOpenProps) => {
  const [inputText, setInputText] = useState<string>('');

  return (
    <td className={styles['table-cell']}>
      <label className={styles['cell-label']} htmlFor={`${row.name}-response-${column.code}`}>
        <input
          data-testid="response-field"
          className={styles['input-field']}
          id={`${row.name}-response-${column.code}`}
          type="text"
          value={inputText}
          data-column={column.code}
          name={`${row.name}-response`}
          aria-label={`${row.text}: ${column.text}`}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
        />
      </label>
    </td>
  );
};

export default GridOpen;
