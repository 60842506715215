import { PanopticQdfAPIResponse, QuestionType } from '../../types/panoptic';

export const filterDefinitionFile = (widgetName: string, data: PanopticQdfAPIResponse | any) => {
  const definitionFile = data.question_types;

  const widgetDefinitions: QuestionType = Object.keys(definitionFile).reduce(
    (acc, questionName) => {
      if (questionName === widgetName) return { ...acc, ...definitionFile[questionName] };

      return acc;
    },
    { options: {}, export: [] }
  );

  return widgetDefinitions;
};
