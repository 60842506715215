import { IconButton } from '@mui/material';
import { IResponseOption, QdfQuestion } from '../../../types';
import GridHeader from './GridHeader';
import GridButton from './GridButton';
import styles from './RowOptions.module.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GridOpen from './GridOpen';

interface RowOptionsProps {
  widgetName: string;
  name: string;
  rows?: QdfQuestion[];
  columns?: IResponseOption[];
  onChangeRows: (inputFields: QdfQuestion[]) => void;
}

const RowOptions = ({ name, widgetName, rows = [], onChangeRows, columns }: RowOptionsProps) => {
  const rowCode = Number(rows[rows.length - 1].name.split('_')[1]) + 1;

  const addRows = () => {
    onChangeRows([
      ...rows,
      {
        options: {},
        text: '',
        name: `${name}_${rowCode}`,
        type: 'grid-item',
        element: 'qdf:question',
        export: [{ name: `${name}_${rowCode}` }],
      },
    ]);
  };

  const rowTextHandler = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    let data = [...rows];
    data[index] = { ...data[index], text: e.target.value };
    onChangeRows(data);
  };

  const removeRows = (index: number) => {
    let data = [...rows];
    data.splice(index, 1);
    onChangeRows([...data]);
  };
  return (
    <tbody>
      {rows.map((row, index) => (
        <tr className={styles.row} key={row.name}>
          <GridHeader
            cell={row}
            scope="row"
            index={index}
            onChangeText={rowTextHandler}
            onRemove={removeRows}
          />
          {columns?.map((column) =>
            widgetName === 'grid-open' ? (
              <GridOpen key={`${row.name}-${column.code}`} row={row} column={column} />
            ) : (
              <GridButton
                widgetName={widgetName}
                key={`${row.name}-${column.code}`}
                column={column}
                row={row}
              />
            )
          )}
        </tr>
      ))}
      <tr>
        <td>
          <IconButton onClick={addRows} color="success">
            <AddCircleIcon data-testid="add-row" />
          </IconButton>
        </td>
      </tr>
    </tbody>
  );
};

export default RowOptions;
