import { IResponseOption } from '../../types';
import ResponseCSS from './ResponseOption.module.css';
import { Button } from '@mui/material';

interface ResponseOptionProps {
  name: string;
  index: number;
  input: IResponseOption;
  value: string;
  isSelected: boolean;
  onChange: Function;
  onRemove: Function;
  onValueUpdate: Function;
  multiple?: boolean;
  showTickbox: boolean;
  inputRef: React.Ref<HTMLInputElement>;
}

const ResponseOption = ({
  name,
  index,
  input,
  value,
  isSelected,
  onChange,
  onRemove,
  onValueUpdate,
  multiple = false,
  showTickbox,
  inputRef,
}: ResponseOptionProps) => {
  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueUpdate(e.target.value);
  };

  return (
    <div
      key={index}
      className={
        isSelected ? `${ResponseCSS.option} ${ResponseCSS.selected}` : `${ResponseCSS.option}`
      }>
      {showTickbox && (
        <span className={ResponseCSS['uniform-response']}>
          <input
            className={ResponseCSS.radio}
            type={multiple ? 'checkbox' : 'radio'}
            name={`${name}-input`}
            onChange={updateState}
            id={`${name}-response-${index.toString()}`}
            value={value}
          />
        </span>
      )}

      <label htmlFor={`${name}-response-${index.toString()}`}>
        <input
          ref={inputRef}
          className={ResponseCSS['custom-choice']}
          name="option"
          placeholder="Enter choice..."
          value={input.text}
          onChange={(e) => onChange(index, e)}
        />
      </label>

      <Button color="error" onClick={() => onRemove(index)}>
        Remove
      </Button>
    </div>
  );
};

export default ResponseOption;
