import { IResponseOption, QdfQuestion } from '../../types';
import { Stack, Button, Modal } from '@mui/material';
import ResponseOptions from '../ResponseOptions/ResponseOptions';
import styles from './ResponseOptionsModal.module.css';
import { MouseEventHandler } from 'react';

interface ResponseOptionsModalProps {
  name: string;
  edit: boolean;
  onSetEdit: Function;
  onSaveData: MouseEventHandler<HTMLButtonElement>;
  qdf: QdfQuestion;
  responseOptions: IResponseOption[];
  onSetResponseOptions: (inputFields: IResponseOption[]) => void;
}

const ResponseOptionsModal = ({
  name,
  onSetEdit,
  edit,
  qdf,
  responseOptions,
  onSetResponseOptions,
  onSaveData,
}: ResponseOptionsModalProps) => {
  const handleClose = () => {
    qdf.responses && onSetResponseOptions([...qdf.responses.items]);
    onSetEdit(false);
  };

  return (
    <Modal
      data-testid="modal"
      className={styles.modal}
      open={edit}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Stack spacing={2} className={styles['modal-content']}>
        <header className={styles['header']}>
          <span className={styles['modal-title']}>Edit choices</span>
          <span className={styles['modal-description']}>
            Click 'Add option' and write your choices below.
          </span>
        </header>

        <div className={styles['choices']}>
          <ResponseOptions
            responseOptions={responseOptions}
            onChange={onSetResponseOptions}
            name={name}
            showTickbox={false}
          />
        </div>

        <Stack
          direction={'row'}
          spacing={0.7}
          justifyContent={'flex-end'}
          alignItems={'flex-end'}
          className={styles['call-to-action-btns']}>
          <Button
            style={{ maxHeight: '35px' }}
            variant="outlined"
            color="error"
            onClick={handleClose}>
            Cancel
          </Button>

          <Button style={{ maxHeight: '35px' }} variant="outlined" onClick={onSaveData}>
            Save Choices
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
export default ResponseOptionsModal;
