import AppCSS from './App.module.css';
import { WidgetBuilder } from './containers/WidgetBuilder/WidgetBuilder';
import logo from './YGlogo.png';

function App() {
  return (
    <div className={AppCSS.container}>
      <img className={AppCSS.logo} src={logo} alt="logo" />
      <WidgetBuilder widgetName="single" name="s_1" />
      {/* <WidgetBuilder widgetName="multi" name="m_1" /> */}
      {/* <WidgetBuilder widgetName="dropdown" name="d_1" /> */}
      {/* <WidgetBuilder widgetName="grid" name="g1" /> */}
      {/* <WidgetBuilder widgetName="grid-check" name="gc1" /> */}
      {/* <WidgetBuilder widgetName="grid-open" name="go1" /> */}
    </div>
  );
}

export default App;
