import { Stack, Switch } from '@mui/material';
import { QdfQuestion } from '../../types';

interface BooleanControlProps {
  optionName: string;
  qdf: QdfQuestion;
  widgetDefinitions: Record<string, any>;
  onOptionChange: Function;
}

const BooleanControl = ({
  optionName,
  qdf,
  widgetDefinitions,
  onOptionChange,
}: BooleanControlProps) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <span>{optionName}</span>

      <Switch
        data-testid={`${optionName}-toggle`}
        defaultChecked={widgetDefinitions.options[optionName].default}
        value={qdf.options[optionName]}
        onChange={() =>
          onOptionChange({
            ...qdf.options,
            [optionName]: !qdf.options[optionName],
          })
        }
      />
    </Stack>
  );
};

export default BooleanControl;
