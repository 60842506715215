import { IResponseOption, QdfQuestion } from '../../../types';
import EditableQuestionText from '../../EditableQuestionText/EditableQuestionText';
import ColumnOptions from './ColumnOptions';
import RowOptions from './RowOptions';
import styles from './GridWidget.module.css';
import { Stack } from '@mui/material';

interface GridProps {
  widgetName: string;
  name: string;
  onVisualChange: (question: QdfQuestion) => void;
  qdf: QdfQuestion;
}

const GridWidget = ({ onVisualChange, qdf, name, widgetName }: GridProps) => {
  const columns = qdf.responses?.items;
  const rows = qdf.subquestion;

  const questionTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onVisualChange({
      ...qdf,
      text: e.target.value,
    });
  };

  const onChangeColumns = (columns: IResponseOption[]) => {
    onVisualChange({
      ...qdf,
      responses: {
        element: 'qdf:response_group',
        items: columns,
      },
    });
  };

  const onChangeRows = (rows: QdfQuestion[]) => {
    onVisualChange({
      ...qdf,
      subquestion: rows,
    });
  };

  return (
    <Stack spacing={4} flexWrap="wrap">
      <EditableQuestionText onChangeText={questionTextChangeHandler} value={qdf.text} />
      <div className={styles['grid-wrapper']}>
        <table className={styles.table}>
          <thead>
            <ColumnOptions columns={columns} onChangeColumns={onChangeColumns} />
          </thead>
          <RowOptions
            widgetName={widgetName}
            name={name}
            rows={rows}
            columns={columns}
            onChangeRows={onChangeRows}
          />
        </table>
      </div>
    </Stack>
  );
};

export default GridWidget;
