import { IconButton } from '@mui/material';
import { IResponseOption } from '../../../types';
import GridHeader from './GridHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface ColumnOptionsProps {
  columns?: IResponseOption[];
  onChangeColumns: (inputFields: IResponseOption[]) => void;
}

const ColumnOptions = ({ columns = [], onChangeColumns }: ColumnOptionsProps) => {
  const addColumns = () => {
    const columnCode = columns[columns.length - 1].code + 1;
    onChangeColumns([...columns, { text: '', code: columnCode, element: 'qdf:response' }]);
  };

  const columnTextHandler = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    let data = [...columns];
    data[index] = { ...data[index], text: e.target.value };
    onChangeColumns(data);
  };

  const removeColumns = (index: number) => {
    let data = [...columns];
    data.splice(index, 1);
    onChangeColumns([...data]);
  };

  return (
    <tr>
      <td></td>
      {columns.map((column, index) => (
        <GridHeader
          cell={column}
          key={column.code}
          index={index}
          scope="column"
          onChangeText={columnTextHandler}
          onRemove={removeColumns}
        />
      ))}
      <td align="right">
        <IconButton color="success" onClick={addColumns}>
          <AddCircleIcon data-testid="add-column" />
        </IconButton>
      </td>
    </tr>
  );
};

export default ColumnOptions;
