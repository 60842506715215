import React from 'react';
import Dropdown from '../components/widgets/Dropdown/Dropdown';
import GridWidget from '../components/widgets/Grid/GridWidget';
import Multi from '../components/widgets/Multi/Multi';
import Single from '../components/widgets/Single/Single';
import { QdfQuestion } from '../types';

interface WidgetComponent {
  name: string;
  displayName?: string;
  Component: typeof React.Component;
  getInitialQdf: (name: string) => QdfQuestion;
}

export const widgets: WidgetComponent[] = [
  {
    name: 'single',
    displayName: 'Single Choice',
    Component: Single as unknown as typeof React.Component,
    getInitialQdf: (name) => ({
      text: '',
      element: 'qdf:question',
      options: {},
      type: 'single',
      responses: {
        element: 'qdf:response_group',
        items: [],
      },
      name,
      export: [{ name }],
    }),
  },
  {
    name: 'multi',
    displayName: 'Multi Choice',
    Component: Multi as unknown as typeof React.Component,
    getInitialQdf: (name) => ({
      text: '',
      element: 'qdf:question',
      type: 'multiple',
      options: {},
      responses: {
        element: 'qdf:response_group',
        items: [],
      },
      name,
      export: [{ name }],
    }),
  },
  {
    name: 'dropdown',
    displayName: 'Dropdown',
    Component: Dropdown as unknown as typeof React.Component,
    getInitialQdf: (name) => ({
      text: '',
      element: 'qdf:question',
      type: 'dropdown',
      options: {},
      responses: {
        element: 'qdf:response_group',
        items: [],
      },
      name,
      export: [{ name }],
    }),
  },
  {
    name: 'grid',
    displayName: 'Grid',
    Component: GridWidget as unknown as typeof React.Component,
    getInitialQdf: (name) => ({
      text: '',
      element: 'qdf:question',
      type: 'grid',
      options: {},
      subquestion: [
        {
          text: '',
          name: `${name}_0`,
          type: 'grid-check-item',
          element: 'qdf:question',
          export: [{ name: `${name}_0` }],
          options: {},
        },
      ],
      responses: {
        element: 'qdf:response_group',
        items: [{ text: '', code: 0, element: 'qdf:response' }],
      },
      name,
      export: [{ name }],
    }),
  },
  {
    name: 'grid-check',
    displayName: 'Grid-check',
    Component: GridWidget as unknown as typeof React.Component,
    getInitialQdf: (name) => ({
      text: '',
      element: 'qdf:question',
      type: 'grid-check',
      options: {},
      subquestion: [
        {
          text: '',
          name: `${name}_0`,
          type: 'grid-check-item',
          element: 'qdf:question',
          export: [{ name: `${name}_0` }],
          options: {},
        },
      ],
      responses: {
        element: 'qdf:response_group',
        items: [{ text: '', code: 0, element: 'qdf:response' }],
      },
      name,
      export: [{ name }],
    }),
  },
  {
    name: 'grid-open',
    displayName: 'Grid-open',
    Component: GridWidget as unknown as typeof React.Component,
    getInitialQdf: (name) => ({
      text: '',
      element: 'qdf:question',
      type: 'grid-open',
      options: {},
      subquestion: [
        {
          text: '',
          name: `${name}_0`,
          type: 'grid-check-item',
          element: 'qdf:question',
          export: [{ name: `${name}_0` }],
          options: {},
        },
      ],
      responses: {
        element: 'qdf:response_group',
        items: [{ text: '', code: 0, element: 'qdf:response' }],
      },
      name,
      export: [{ name }],
    }),
  },
];
